
import ReactGA from "react-ga4";
import Footer from "../../containers/Home/footerlp.tsx";

function PrivacyPolicy() {
  return (
 <div>
       <div className="bg-[#F9FAF4]  px-[50px] sm:px-[100px] flex  pt-[150px] flex-col md:px-[312px]">
    <div className="">
        <h1 className="text-5xl md:text-8xl font-black">Prymshare Terms and Conditions</h1>
        <p className="font-bold pt-3 pb-20">Last Updated Date: October 9th, 2024</p>
    </div>

    <div className="pb-10">
       <h1 className="font-bold text-3xl">Acceptance of Terms</h1> 
       <p className="font-extralight text-[#797E6F]
">By using Prymshare's web application, you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms, please do not use Prymshare.</p>
    </div>

    <div className="pb-10">
        <h1 className="font-bold  text-3xl pb-3">User Accounts</h1>
        <p className="font-extralight pb-10 text-[#797E6F]">To use certain features of Prymshare, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
       </p>

       <p className="font-extralight text-[#797E6F]">
       You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
       </p>
       </div>

       <div className="pb-10">
        <h1 className="font-bold  text-3xl pb-3">User Content</h1>
        <p className="font-extralight pb-10 text-[#797E6F]">By submitting content to Prymshare, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, distribute, and display the content in connection with Prymshare's services. 
            
       </p>

       <p className="font-extralight text-[#797E6F]">
       You retain ownership of any intellectual property rights in the content you submit.
       </p>
       </div>


       <div className="pb-10">
        <h1 className="font-bold  text-3xl pb-3">Prohibited Conduct</h1>
        <div className="font-extralight pb-10 text-[#797E6F] gap-5 flex flex-col">
        <p className="pb-10">
        While using Prymshare, you agree not to engage in any conduct that violates these terms, including but not limited to the following:
        </p>
        <span> Violating applicable laws or regulations </span>
        <span> Impersonating any person or entity  </span>
        <span>  Uploading malicious code or engaging in harmful activities </span>
        <span> Interfering with the proper functioning of Prymshare </span>
       
          
        </div>
    

       <p className="font-extralight text-[#797E6F]">
       You retain ownership of any intellectual property rights in the content you submit.
       </p>
       </div>

       <div className="pb-20">
        <h1 className="font-bold  text-3xl pb-3">Termination</h1>
        <p className="font-extralight pb-10 text-[#797E6F]">
        We reserve the right to suspend or terminate your access to Prymshare at our sole discretion, with or without cause and with or without notice.
       </p>

    
       </div>
   </div>
   <Footer/>
 </div>
  );
}

export default PrivacyPolicy;

