import React, {useState} from 'react'

import check from "../../images/check.png"

const Prizing = () => {
    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };

  return (
    <div className='pt-20 bg-[#E8E8E8]'>
        <div className='mt-10 pt-10 flex justify-center text-center ' >
           <div className='flex gap-6 flex-col'>
             <h1 className='font-black text-7xl'>Pricing</h1>
             <p>Save up to 30% </p>

             <div className={` bg-white rounded-full h-[50px] flex gap-2 px-2 py-5  items-center justify-center `}>
              <div   onClick={() => handleTabClick('tab1')}  className={` ${activeTab === 'tab1' ? "text-white bg-[#00181B]" : "text-black"}  w-[50%] h-[100%] rounded-full flex justify-center items-center px-4 py-5 `}>
               <p>Monthly</p>
              </div>
              <div onClick={() => handleTabClick('tab2')}  className={` ${activeTab === 'tab2' ? "text-white bg-[#00181B]" : "text-black"}  w-[50%] h-[100%] rounded-full flex justify-center items-center px-4 py-5 `}>
              <p>Annually</p>
              </div>
             </div>

           </div>
        </div>
        
        <div className='flex gap-5 items-start justify-center w-full py-10 mt-10 px-4 flex-wrap md:flex-nowrap con' >

        <div className='max-w-[392px] w-[90%] lg:w-[60%] mb-5'>
               <div className='bg-[#1B0015] py-7 rounded-t-[20px]'>
               <h3 className='text-white px-4 text-6xl font-bold'>Free</h3> 
              </div>
           <div className='w-[100%] bg-white p-4' >
           <div className='flex flex-col mb-4 justify-between font-thin text-[#6B7280] gap-10" 
'>
            <p className='p-2'> For your personal Prymshare</p>
            <p className='p-2'>Everything you do, in one single link</p>
            <p className='p-2'>Key features</p>
            </div>
            <hr
      className="mt-12 mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50  w-[90%]"
    />
        <div className='flex gap-3 font-thin text-[#6B7280] '>
          <img src={check} alt="" className='w-[20px]' />
          <p>Unlimited links</p>
        </div>
        <hr
      className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 mb-[250px] w-[90%]"
    />
 <div className='flex flex-col gap-4 '>
    <h1 className='text-6xl font-semibold'>NGN <span>0</span></h1>
    <div className='font-thin text-[#6B7280] py-2'>
      <p>Free, Forever</p>
    </div>
     <div className='border-slate-100 py-5 px-5 rounded-full border-[1px] text-center mb-5'>
       Join us for free
     </div>
    </div>
     </div>
     </div>


{/* second */}
<div className='max-w-[392px] w-[90%] lg:w-[60%] mb-5'>
               <div className='bg-[#121B00] py-7 rounded-t-[20px]'>
               <h3 className='text-white px-4 text-6xl font-bold'>Starter</h3> 
              </div>
           <div className='w-[100%] bg-white p-4' >
           <div className='flex flex-col mb-4 justify-between font-thin text-[#6B7280] gap-10" 
'>
            <p className='p-2'> For your personal Prymshare</p>
            <p className='p-2'>Everything you do, in one single link</p>
            <p className='p-2'>Key features</p>
            </div>
            <hr
      className="mt-12 mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50  w-[90%]"
    />
        <div className='flex gap-3 font-thin text-[#6B7280] '>
          <img src={check} alt="" className='w-[20px]' />
          <p>Unlimited links</p>
        </div>
        <hr
      className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 mb-[250px] w-[90%]"
    />
 <div className='flex flex-col gap-4 '>
    <h1 className='text-6xl font-semibold'>NGN <span>4999</span></h1>
    <div className='font-thin text-[#6B7280] py-2'>
      <p>NGN/month, billed monthly</p>
    </div>
     <div className='border-slate-100 py-5 px-5 rounded-full border-[1px] text-center mb-5 bg-[#121B00] text-white' >
     Get Starter
     </div>
    </div>
     </div>
     </div>



{/* // third */}

<div className='max-w-[392px] w-[90%] lg:w-[60%] mb-5'>
               <div className='bg-[#00181B] py-7 rounded-t-[20px]'>
               <h3 className='text-white px-4 text-6xl font-bold'>Pro</h3> 
              </div>
           <div className='w-[100%] bg-white p-4' >
           <div className='flex flex-col mb-4 justify-between font-thin text-[#6B7280] gap-10" 
'>
            <p className='p-2'> For your personal Prymshare</p>
            <p className='p-2'>Everything you do, in a single link</p>
            <p className='p-2'>Key features</p>
            </div>
            <hr
      className="mt-12 mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50  w-[90%]"
    />
        <div className='flex gap-3 font-thin text-[#6B7280] '>
          <img src={check} alt="" className='w-[20px]' />
          <p>Unlimited links</p>
        </div>
        <hr
      className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 mb-[250px] w-[90%]"
    />
 <div className='flex flex-col gap-4 '>
    <h1 className='text-6xl font-semibold'>NGN <span>11999</span></h1>
    <div className='font-thin text-[#6B7280] py-2'>
      <p>NGN/month, billed monthly</p>
    </div>
     <div className='border-slate-100 py-5 px-5 rounded-full border-[1px] text-center mb-5 bg-[#00181B] text-white'>
  Get Pro
     </div>
    </div>
     </div>
     </div>
        </div>
    </div>
  )
}

export default Prizing