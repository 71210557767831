import { useEffect, useState } from 'react';
import { Outlet, Link, NavLink, useLocation, useNavigate  } from "react-router-dom";
import './nav.css';

import { useRecoilState, useRecoilValue } from 'recoil';
import { authenticated } from "../../state/atoms/Authenticated";
import { access } from "../../state/atoms/Authenticated";
import { refresh } from "../../state/atoms/Authenticated";

import { checkAuth, logout } from '../../state/actions/auth';
import Logo from '../../images/new_logo.png';
import LogoMain from '../../images/logo-main.png';
import Logo2 from '../../images/prymshare.png';
import Logo3 from '../../images/prymshare2.png';
import Navigation from '../../images/Navigation.png';
import DismissCircle from '../../images/DismissCircle.png';
import Icon from "../../images/Icon.png"

import Appearance from '../../images/appearance.png';
import Analytics from '../../images/analytics.png';
import Settings from '../../images/settings.png';
import Share from '../../images/share.png';
import Profilepic from '../../images/profilepicc.png';

import Account from '../../images/account.png';
import Wallet from '../../images/wallet.png';
import Product from '../../images/product.png';
import Logoouut from '../../images/logout.png';
import Feedback from '../../images/feedback.png';
import Questions from '../../images/question.png';
import exit from "../../images/exit.png"
import chats from "../../images/chats.png"
import dollar from "../../images/dollar.png"

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

import { ReactComponent as AnalyticsIcon } from '../../images/charts.svg';
import { ReactComponent as AppearanceIcon } from '../../images/lay.svg';
import { ReactComponent as CustomizeIcon } from '../../images/Credit.svg';

import ShareIcon from '../../images/menu-share.png';
import UserProfileIcon from '../../images/profile.png';

import Free from '../../images/free.png';

import { RWebShare } from "react-web-share";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';



const ProfileNav = ({ loggedCreatorUname, loggedCreatorDname, loggedCreatorData }) => {
    const isAuth = useRecoilValue(authenticated);
    const [authState, setAuthState] = useRecoilState(authenticated);
    const [accessState, setAccessState] = useRecoilState(access);
    const [refreshState, setRefreshState] = useRecoilState(refresh);

    const [sidebar, setSidebar] = useState(false);
    const [open, setOpen] = useState(false);

    let logo = `${loggedCreatorData.creator.logo}`

    const showSidebar = () => {
        console.log(isAuth + ' for navigation');
        setSidebar(!sidebar);

    } 

    const formatPathname = (pathname) => {
        // Remove '/me/' prefix
        const section = pathname.replace('/me/', '').replace('/', '');
        // Capitalize the first letter
        return section.charAt(0).toUpperCase() + section.slice(1);
      };
      


    const TopNav = () => {
        const location = useLocation();
        const pageTitle = formatPathname(location.pathname);

        let creator_link = `${process.env.REACT_APP_FRONTEND_URL}/${loggedCreatorUname}`;
      
        return (
          <div className="top-nav">
            <div className="title"><img className='hidden lg:flex desktoplogo' src={Logo} /><img className='flex lg:hidden mobilelogo' src={LogoMain} /></div>
            <div className="icons">
                <RWebShare
                    data={{
                        url: creator_link,
                        title: loggedCreatorUname,
                    }}
                    onClick={() => console.log("shared successfully!")}
                    >
                    <img src={ShareIcon} className="nav-icon" />
                </RWebShare>
              
              <img src={logo} onClick={showSidebar} className="nav-icon" />
            </div>


            {sidebar ?
                <div className='profile-nav-modal'>
                    <div className="mode-top">
                        <img src={logo} className="profile-nav-icon" />
                        <div className="humph">
                            <div className="mb-[5px] font-bold">
                                 <h1>{loggedCreatorDname}</h1> 
                                
                               
                            </div>
                            <div className="flex gap-2 items-center h-[100%]"> 
                                <h4>https://prymshare.co/{loggedCreatorUname}</h4>
                             
                                  <div className='bg-[#D2D8C7] h-[4px] w-[4px] rounded-full
'/>
                             
                                <h4 className='font-semibold'>Free</h4>
                                 
                                 </div>
                        </div>
                    </div>
                    {/* <p className='mt-4 mb-2 block cursor-pointer w-full px-6 py-2 text-center lg:px-5 lg:py-3 text-xs font-medium text-white bg-[radial-gradient(circle_at_right,_var(--tw-gradient-stops))] from-accent-500 to-primaryy-500 hover:bg-secondaryy-600 rounded-full'>Go Prime with Pro! Coming soon!</p> */}
                    <div className="flex gap-3 mb-5 cursor-pointer py-2 w-[100%] bg-[#121B00] mt-[20px] justify-center rounded-full items-center text-white"
                    
                    >
                 <img src={Icon} alt=""/>
                 {/* <h3>Go Pro for free</h3>
                  */}
                   <h3>Go Pro for free</h3>
                 </div>
                    <div className="flex flex-col">
                    <Link to='/me/feedback' onClick={() => setSidebar(!sidebar)} className='nav-links'><img src={chats} /><p>Submit Feedback</p></Link>
                        <Link to='/me/payment' onClick={() => setSidebar(!sidebar)} className='nav-links'><img src={dollar} /><p>Payment Settings</p></Link>
                        {/* <Link to='/me/products' onClick={() => setSidebar(!sidebar)} className='nav-links'><img src={Product} /><p>My Products</p></Link> */}
                       
                        <div className='nav-links' onClick={() => logout(setAuthState, setAccessState, setRefreshState)}><img src={exit} /><p className='text-error-600'>Logout</p></div>
                    </div>
                </div> 
                :
                <></>
            }
          </div>
        );
      };


	return (
        <>
            <TopNav />
            
            <div className="bottom-nav ">
                <NavLink exact to="/me/appearance" >
                    <div className="nav-item" activeClassName="active">
                        <AppearanceIcon className="nav-icon" style={{filter: 'invert(.7)', fill: 'none'}} />
                        <span>Appearance</span>
                    </div>
                </NavLink>
                <NavLink to="/me/analytics" >
                    <div className="nav-item" activeClassName="active">
                        <AnalyticsIcon className="nav-icon" style={{filter: 'invert(.7)'}} />
                        <span>Analytics</span>
                    </div>
                </NavLink>

                <NavLink to="/me/account" >
                    <div className="nav-item" activeClassName="active">
                        <CustomizeIcon className="nav-icon" style={{filter: 'invert(.7)'}} />
                        <span>Payments</span>
                    </div>
                </NavLink>
                
            </div>
            
        </>
        
        
	);
}

export default ProfileNav;