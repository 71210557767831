import { useEffect, useState, useRef } from 'react';
import {Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import Axios from 'axios';

import './profiledash.css'
import Divider from './divider.tsx';
import { useRecoilState, useRecoilValue } from 'recoil';

import { authenticated } from "../../state/atoms/Authenticated";

import { iscreator, theme, loggedcreator, loggedcreatordata, loggeddname, loggeduname } from "../../state/atoms/Creator";

import { EditCreatorProfile } from '../../state/actions/creator';

import EditLinks from './editLinks.tsx';

import { Helmet } from 'react-helmet';

import ProfilePic from '../../images/profilepiccc.png';
import Share from '../../images/share.png';
import Copy from '../../images/Copy.png';
import Preview from '../../images/Open.png';
import Prevw from '../../images/preview.png';
import Pro from '../../images/Flash.png';
import Zap from '../../images/Zap.png';
import DragIcon from '../../images/drag.png';

import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
  TECollapse,
} from "tw-elements-react";
import Select from 'react-select';
import IntroDemo from '../BioPage/Demos/intro.tsx';
import LinksDemo from '../BioPage/Demos/links.tsx';
import VideoDemo from '../BioPage/Demos/videos.tsx';
import AudioDemo from '../BioPage/Demos/audio.tsx';
import TextBlockDemo from '../BioPage/Demos/textblock.tsx';
import QuoteApp from './   profiledashblocks.tsx';
import PageOrder from './pageorder.tsx'
import AddBlockBtn from './addBlockBtn.tsx';

import imageCompression from 'browser-image-compression';

import { RWebShare } from "react-web-share";


const ProfileDash = () => { 
  let navigate = useNavigate();

  const [profileDet, setProfileDet] = useState([]);
  const [loadingProfile, setLoadingProfile] = useState(true);

  const isAuth = useRecoilValue(authenticated);

  const creatorData = useRecoilValue(loggedcreatordata);
  
  const [loggedCreator, setLoggedCreator] = useRecoilState(loggedcreator);
	const [loggedCreatorData, setLoggedCreatorData] = useRecoilState(loggedcreatordata);
	const [loggedCreatorDname, setLoggedCreatorDname] = useRecoilState(loggeddname);
	const [loggedCreatorUname, setLoggedCreatorUname] = useRecoilState(loggeduname);

  const creatorsData = loggedCreatorData.creator;
  const socialsData = loggedCreatorData.socials;
  let socialsId = null;
  if (socialsData != null) {
    socialsId = socialsData.id;
  } else {
    socialsId = null;
  }
  
  useEffect(() => {
    // console.log(loggedCreator);
  	if (!loggedCreator && loggedCreator !='loading') {
			return navigate("/complete-profile");
  	}
  }, [loggedCreator]);


  const [selectedOption, setSelectedOption] = useState(null);

    const options = [
        { value: 'Influencer', label: 'Influencer' },
        { value: 'YouTuber', label: 'YouTuber' },
        { value: 'Podcaster', label: 'Podcaster' },
        { value: 'Content Creator', label: 'Content Creator' },
        { value: 'Media Organisation', label: 'Media Organisation' },
        { value: 'Writer', label: 'Writer' },
        { value: 'Designer', label: 'Designer' },
        { value: 'Event Host', label: 'Event Host' },
        { value: 'Marketer', label: 'Marketer' },
        { value: 'Product Manager', label: 'Product Manager' },
        { value: 'Software Engineer', label: 'Software Engineer' },
        { value: 'Personal Brand', label: 'Personal Brand' },
        { value: 'Public Figure', label: 'Public Figure' },
        { value: 'Researcher', label: 'Researcher' },
        { value: 'Music Creator', label: 'Music Creator' },
        { value: 'Vlogger', label: 'Vlogger' },
        { value: 'Comedian', label: 'Comedian' },
        { value: 'Educator', label: 'Educator' },
        { value: 'NGO', label: 'NGO' },
        { value: 'Photographer', label: 'Photographer' },
        { value: 'Blogger', label: 'Blogger' },
        { value: 'Social Media Manager', label: 'Social Media Manager' },
        { value: 'Author', label: 'Author' },
        { value: 'Techie', label: 'Techie' },
        { value: 'Digital Artist', label: 'Digital Artist' },
    ];
    
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState(loggedCreatorData.creator.creator_username)
    const [displayName, setDisplayName] = useState(loggedCreatorData.creator.display_name)

    const [userNameAvailable, setUserNameAvailable] = useState(null)

    const [errorMessage, setErrorMessage] = useState(null)

    const [loadingHeader, setLoadingHeader] = useState(false)

    const [submittable, setSubmittable] = useState(false);

    const [bio, setBio] = useState(loggedCreatorData.creator.creator_desc);

    const [links, setLinks] = useState({
      youtube: '',
      tiktok: '',
      linkedin: '',
      spotify: '',
      facebook: '',
      instagram: '',
      twitter: '',
      vimeo: '',
    });

    
  const [activeElement, setActiveElement] = useState("");
  const [widgets, setWidgets] = useState(['Widget B', 'Widget C', 'Widget D', 'Widget E', 'Widget F']);

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(true);

    useEffect(() => {
      console.log(selectedOption)
      if (bio != '' && displayName != ''  && selectedOption != null && isValid) {
          setSubmittable(true);
      } else {
          setSubmittable(false);
      }
  }, [bio, displayName, selectedOption, isValid]);

  const [textBlock, setTextBlock] = useState({ present: false, id: '' });
  const [videoBlock, setVideoBlock] = useState({ present: false, id: '' });
  const [galleryBlock, setGalleryBlock] = useState({ present: false, id: '' });
  const [paywall, setPaywall] = useState({ present: false, id: '' });
  const [giftBlock, setGiftBlock] = useState({ present: false, id: '' });

  const [highestId, setHighestId] = useState(0);
  

    const onUsernameChange = (e) => {
      setUsername(e.target.value);
      const regex = /[^\w]/;
      if (regex.test(e.target.value)) {
          setErrorMessage('Username should not contain punctuation or space characters.');
          setUserNameAvailable(null);
      } else {
          setUserNameAvailable('loading');
          setErrorMessage(null);
          axios
          .get(`${process.env.REACT_APP_API_URL}/api/profile/check/${e.target.value}`)
          .then((res) => {
          //   console.log(res);
          setUserNameAvailable(res.data);
          })
          .catch((err) => {
          console.log("Error", err);
          });
      }
    }

    const handleChange = (event) => {
      const { name, value } = event.target;
      setLinks({
        ...links,
        [name]: value
      });
  
      // Validate URL only if value is not empty
      if (value.trim() !== '') {
        validateURL(value, name);
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          [name]: null // Reset error message for this field
        }));
        setIsValid(true);
      }
    };
  
    const validateURL = (url, fieldName) => {
      // Regular expression for validating URLs
      const urlRegex = /^(ftp|http|https):\/\/[^ "]*$/;
      const isHereValid = urlRegex.test(url)
      setIsValid(urlRegex.test(url));
      setErrors(prevErrors => ({
        ...prevErrors,
        [fieldName]: isHereValid ? null : 'This link is invalid. All fields should begin with "https://"'
      }));
    };

    

    const onSubmit = e => {
      e.preventDefault();
      setLoadingHeader(true);
      // console.log(selectedOption.value);
      let formData = new FormData();
      if (prologo != null) {
        formData.append('logo', prologo);
      } else if (editLogo == null) {
        formData.append('logo', '');
      }
      formData.append('display_name', displayName);
      // formData.append('creator_username', username);
      formData.append('creator_desc', bio);
      formData.append('category', selectedOption.value);
      
      let socialsFormData = new FormData();
      socialsFormData.append('youtube', links.youtube);
      socialsFormData.append('spotify', links.spotify);
      socialsFormData.append('twitter', links.twitter);
      socialsFormData.append('facebook', links.facebook);
      socialsFormData.append('instagram', links.instagram);
      socialsFormData.append('tiktok', links.tiktok);
      socialsFormData.append('linkedin', links.linkedin);
  
      EditCreatorProfile(formData, socialsFormData, username, socialsId, setLoadingHeader, setShowModal1, navigate, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname);
    };
  

  useEffect(() => {
    console.log(creatorData);
  }, [creatorData]);
  


  useEffect(() => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };


    const fetchData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile/`, config);
            setProfileDet(res.data.creator);
            console.log(res.data[0]);
            console.log(res.data);
            console.log(res.data.creator.display_name);
            setLoadingProfile(false);
        }
        catch (err) {
          console.log('error')
        }
    }

    fetchData(); 
  }, []);

  useEffect(() => {
    if (!isAuth) {
      return navigate("/register");
    }
  }, [isAuth]);

  
  

  const handleClick = (value: string) => {
      if (value === activeElement) {
        setActiveElement("");
      } else {
        setActiveElement(value);
      }
    };

  const handleOnDrag = (e: React.DragEvent, widgetType: string) => {
    e.dataTransfer.setData("widgetType", widgetType);
  }

  const handlleOnDrop = (e: React.DragEvent) => {
    const widgetType = e.dataTransfer.getData("widgetType") as string;
    console.log("widgetType", widgetType);
    // Since widget already exists, delete off the existing string, and then, add the new string the normal way to the array
    const newArray = [...widgets, widgetType];
    const duplicateIndex = newArray.indexOf(widgetType);
    if (duplicateIndex !== -1 && duplicateIndex < widgets.length) {
      newArray.splice(duplicateIndex, 1);
    }
    setWidgets(newArray);
    // setWidgets([...widgets, widgetType]);
    console.log(widgets);
  }


  // LOGO INPUT STUFFS
  const [editLogo, setEditLogo] = useState(null);
  const [prologo, setPrologo] = useState(null);
	const [prologoUrl, setPrologoUrl] = useState();
  const [progress, setProgress] = useState(0);

  const hiddenFileInput = useRef(null);

	const handleonLogoClick = event => {
		hiddenFileInput.current.click();
	};

  const handleDeleteLogo = () => {
    setPrologo(null);
    setEditLogo(null);
    setPrologoUrl('');
  }



	// const onChange = async (e) => {
  //   	setPrologo(e.target.files[0]);
  //   	setPrologoUrl(URL.createObjectURL(e.target.files[0]));
	// };

  const onChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const options = {
        maxSizeMB: 1, // Max size in MB
        maxWidthOrHeight: 1920, // Max width or height
        useWebWorker: true, // Use web workers for better performance
        onProgress: (progress) => {
          setProgress(Math.round(progress));
        },
      };

      try {
        const compressedBlob = await imageCompression(file, options);

        // Convert the compressed Blob back to a File object
        const compressedFile = new File(
          [compressedBlob],
          file.name,
          { type: compressedBlob.type }
        );

        setPrologo(compressedFile);
        setPrologoUrl(URL.createObjectURL(compressedFile));
        console.log(compressedFile);
        setProgress(0); // Reset progress
      } catch (error) {
        alert('Error compressing the image; please refresh the page and try again!');
        setProgress(0); // Reset progress on error
      }
    }
  };

  useEffect(() => {
    setEditLogo(creatorsData.logo);
    setUsername(creatorsData.creator_username);
    setDisplayName(creatorsData.display_name);
    setBio(creatorsData.creator_desc);
    if (socialsData != null) {
      setLinks({
        youtube: socialsData.youtube, 
        tiktok: socialsData.tiktok,
        linkedin: socialsData.linkedin,
        spotify: socialsData.spotify,
        facebook: socialsData.facebook,
        instagram: socialsData.instagram,
        twitter: socialsData.twitter,
        vimeo: socialsData.youtube,
      });
    } else {
      setLinks({
        youtube: '', 
        tiktok: '',
        linkedin: '',
        spotify: '',
        facebook: '',
        instagram: '',
        twitter: '',
        vimeo: '',
      });
    }
    
    const defaultOption = options.find(option => option.value === creatorsData.category);
    setSelectedOption(defaultOption);
    // console.log(defaultOption)
    // console.log(creatorsData.category);
}, [creatorData, socialsData]);

  const handlleOnDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  }

  const [copySuccess, setCopySuccess] = useState('');
  

  let logo = loadingProfile ? '' : `${profileDet.logo}`;
  let creator_link = loadingProfile ? `${process.env.REACT_APP_FRONTEND_URL}/username` : `${process.env.REACT_APP_FRONTEND_URL}/${profileDet.creator_username}`

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(creator_link);
      alert('Link copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };


  return (
    <main style={{background: '#F0F0EF'}}>
    <div className="profiledash">
        <div className="dash-det">
          <div className="dash-pic">
            <div className="dash-name" onClick={copyToClipboard}>
              <img className='' src={Copy} />
              <p>Your live page: <span className='!text-[#F84F94]'>https://prymshare.co/{loadingProfile ? 'username' : profileDet.creator_username}</span></p>
            </div>
          </div>
          <div className="prev-share">
            <a href={creator_link} target='_blank'><img className='preview-img' src={Prevw} /><p>Preview</p></a>
            <RWebShare
              data={{
                url: creator_link,
                title: loadingProfile ? '' : profileDet.display_name,
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <div className="share"><img className='share-img' src={Share} /><p>Share</p></div>
            </RWebShare>
            
          </div>
        </div>

        <div className="prime-custom">
          <h2 className='!font-bold'>Try Prymshare Pro</h2>
          <p className="do-more lg:max-w-xs">Explore premium links and monetize your content in more ways-and its free</p>

          <div className="pro-soon" onClick={() => navigate('/billing')}>
            <img src={Zap} alt='pro' />
            <p>Go Pro</p>
          </div>
        </div>

        
        <div className='page' onDrop={handlleOnDrop} onDragOver={handlleOnDragOver}>
        <div id="accordionExample" className="mt-7">
            <div className="rounded-xl border border-neutral-200 bg-white">
              <h2 className="mb-0" id="headingOne">
                <button
                  className={`${
                    activeElement === "element1" &&
                    `text-neutral-800 [box-shadow:inset_0_-1px_0_rgba(229,231,235)]`
                  } group font-bold relative flex w-full items-center rounded-t-[15px] rounded-b-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none`}
                  type="button"
                  onClick={() => handleClick("element1")}
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Header
                  <span
                    className={`${
                      activeElement === "element1"
                        ? `rotate-[-180deg] -mr-1`
                        : `rotate-0 fill-[#212529]`
                    } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </button>
              </h2>
              <TECollapse
                show={activeElement === "element1"}
                className="!mt-0 !rounded-b-none !shadow-none"
              >
                <IntroDemo creatorData={loggedCreatorData} setShowModal1={setShowModal1} showModal1={showModal1} />
              </TECollapse>
            </div>
          </div>

          
            
        </div>

       
        <PageOrder />

        {/* MODAL #1 */}
        <TEModal show={showModal1} setShow={setShowModal1} scrollable className='!bg-white [&_*]:!bg-white'>
          <TEModalDialog>
            <TEModalContent>
              <TEModalHeader>
                {/* <!--Modal title--> */}
                <h5 className="text-xl font-medium leading-normal text-neutral-800">
                  Edit Header
                </h5>
                {/* <!--Close button--> */}
                <button
                  type="button"
                  className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  onClick={() => setShowModal1(false)}
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </TEModalHeader>
              {/* <!--Modal body--> */}
              <TEModalBody>
                <form className="" action="#" onSubmit={e => onSubmit(e)}>
                  <div className="upload-logo">
                    <div className="edit-pic">
                      <img src={prologoUrl ? prologoUrl : editLogo} className="profilepic" />
                    </div>
                    {progress > 0 && <div style={{fontSize: '80%'}}>Compressing Image: {progress}%</div>}
                    <div onClick={handleonLogoClick} className="selectbtn-img" style={{cursor: 'pointer'}}>
                      <p>Select</p>
                    </div>
                    <div onClick={handleDeleteLogo} className="deletebtn-img" style={{cursor: 'pointer'}}>
                      <p>Delete</p>
                    </div>
                  
                    <input
                        className='form-control'
                        ref={hiddenFileInput}
                        style={{display:'none'}}
                        id="upload-profile"
                        type='file'
                        onChange={(e) => onChange(e)}
                    />

                    {/* {prologoUrl != null ?
                      <img src={prologoUrl} />
                            
                        :
                        <></>
                    } */}
                  </div>

                  <div>
                      <label htmlFor="email" className="">
                      Display Name
                      </label>
                      <p>This is the name to be displayed on your page.</p>
                      <div className="mt-2">
                      <input
                          id="name"
                          name="name"
                          type="text"
                          required
                          value={displayName}
                          onChange={e => setDisplayName(e.target.value)}
                          className=""
                      />
                      </div>
                  </div>

                  {/* <div className="username-input">
                      <label>Username</label>
                      <div className="username-child">
                          <p className="pre-input">
                          prymshare.co/
                          </p>
                          <p><input className="input-onboard" name='username' value={username} placeholder="your username" onChange={onUsernameChange}
                          /></p>
                      </div>
                      {errorMessage != null && <p className='uname-val'>{errorMessage}</p>}

                      {userNameAvailable && username != '' ? <p style={{color: 'green'}}>Username is available</p> : <></>}
                      {!userNameAvailable && username != '' ?  <p style={{color: 'red'}}>Username is not available</p> : <></>}
                      {userNameAvailable === 'loading' && username != '' ?  <p style={{color: 'green'}}>Checking username availability...</p> : <></>}

                  </div> */}

                  <div className='bio-input'>
                      <label htmlFor="email" className="">
                      Bio
                      </label>
                      <p>Write a brief description of yourself, and what you do, to be displayed on your page.</p>
                      <div className="mt-2">
                      <textarea
                          id="bio"
                          name="bio"
                          type="bio"
                          required
                          value={bio}
                          maxLength={250}
                          onChange={e => setBio(e.target.value)}
                          className=""
                      />
                      </div>
                      {bio != undefined || bio != null ?
                        <div className="char-count text-xs text-neutral-500">
                          {bio.length}/250 characters {/* Character count display */}
                        </div>
                        : <></>
                      }
                      
                  </div>

                  <div className='select'>
                      <label>What do you do?</label>
                      <p>Pick a title that best describes you!</p>
                      <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={options}
                      />
                  </div> 

                  <div className='socials-input'>
                    <label htmlFor="email" className="">
                    Edit your Socials
                    </label>
                  </div>               

                  <div className='socials-input'>
                    <label htmlFor="email" className="">
                    YouTube Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="youtube" value={links.youtube} onChange={handleChange} />
                    </div>
                    {errors.youtube && <p className='uname-val'>{errors.youtube}</p>}
                </div>

                <div className='socials-input'>
                    <label htmlFor="email" className="">
                    Instagram Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="instagram" value={links.instagram} onChange={handleChange} />
                    </div>
                    {errors.instagram && <p className='uname-val'>{errors.instagram}</p>}
                </div>

                <div className='socials-input'>
                    <label htmlFor="email" className="">
                    Twitter(X) Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="twitter" value={links.twitter} onChange={handleChange} />
                    </div>
                    {errors.twitter && <p className='uname-val'>{errors.twitter}</p>}
                </div>

                <div className='socials-input'>
                    <label htmlFor="email" className="">
                    Tiktok Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="tiktok" value={links.tiktok} onChange={handleChange} />
                    </div>
                    {errors.tiktok && <p className='uname-val'>{errors.tiktok}</p>}
                </div>

                <div className='socials-input'>
                    <label htmlFor="email" className="">
                    Facebook Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="facebook" value={links.facebook} onChange={handleChange} />
                    </div>
                    {errors.facebook && <p className='uname-val'>{errors.facebook}</p>}
                </div>

                <div className='socials-input'>
                    <label htmlFor="email" className="">
                    Linkedin Link
                    </label>
                    <div className="mt-2">
                        <input type="text" name="linkedin" value={links.linkedin} onChange={handleChange} />
                    </div>
                    {errors.linkedin && <p className='uname-val'>{errors.linkedin}</p>}
                </div>

                <div className={loadingHeader || !submittable ? 'submit-com-dis' : 'submit-comp'}>
                  {loadingHeader ? 
                    <div
                        className="flex ml-auto h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                        <span
                          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span>
                      </div> :
                    <button
                        type="submit"
                        disabled={loadingHeader || !submittable}
                        className="complete-button"
                    >
                      Continue
                    </button>
                  }
                    {/* <button
                        type="submit"
                        disabled={loadingHeader || !submittable}
                        className="complete-button"
                    >
                    Continue
                    </button> */}
                </div>
              </form>
              </TEModalBody>
              <TEModalFooter>
                <TERipple rippleColor="light">
                  <button
                    type="button"
                    className="inline-block rounded bg-primaryy-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primaryy-700 transition duration-150 ease-in-out hover:bg-primaryy-accent-100 focus:bg-primaryy-accent-100 focus:outline-none focus:ring-0 active:bg-primaryy-accent-200"
                    onClick={() => setShowModal1(false)}
                  >
                    Close
                  </button>
                </TERipple>
              </TEModalFooter>
            </TEModalContent>
          </TEModalDialog>
        </TEModal>

        

        {/* <AddBlockBtn setTextBlock={setTextBlock} setVideoBlock={setVideoBlock} setGalleryBlock={setGalleryBlock} setPaywall={setPaywall} setGiftBlock={setGiftBlock} textBlock={textBlock} videoBlock={videoBlock} galleryBlock={galleryBlock} paywall={paywall} giftBlock={giftBlock} highestId={highestId} setHighestId={setHighestId} /> */}
    </div>
    </main>
  );
}

export default ProfileDash;
